import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/api.service";
import { Auth } from "aws-amplify";

export const getAuth = createAsyncThunk("user/getAuth", async () => {
  const auth = await Auth.currentAuthenticatedUser();

  if (auth.username) {
    // Return this for normal signIn user
    return {
      ...auth.attributes,
      userId: auth.username,
    };
  }

  return auth;
});

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (userId) => {
    try {
      let res;
      let ewUserID;
      ewUserID = localStorage.getItem("ewUserID");
      if (!ewUserID) {
        res = await ApiService.loginCognito(userId);
        localStorage.setItem("ewUserID", res.userID);
      }
      const basicInfo = await ApiService.getBasicInfo(
        ewUserID ? ewUserID : res.userID
      );
      localStorage.setItem("ewBasicInfo", JSON.stringify(basicInfo));
      localStorage.setItem("ewProfilePic", basicInfo.users.profilePicFullURL);
      localStorage.setItem(
        "ewUserName",
        basicInfo.users.firstName !== " " && basicInfo.users.lastName !== " "
          ? basicInfo.users.firstName + " " + basicInfo.users.lastName
          : localStorage.getItem("ewUserName") !== "NA" &&
            localStorage.getItem("ewUserName") !== ""
          ? localStorage.getItem("ewUserName")
          : "NA"
      );
      localStorage.setItem("isOnline", true);
      return basicInfo;
    } catch (error) {
      console.log("ERROR", error);
    }
  }
);

const initialState = {
  userInfo: {
    data: null,
    status: false,
    error: {},
  },
  auth: {
    data: null,
    status: false,
    error: {},
  },
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfoLocally: (state, action) => {
      const { lockEdit } = action.payload;
      state.userInfo.data.lockEdit = lockEdit;
    },
    clearUserState: (state, action) => {
      state.userInfo = {};
      state.auth = {};
    },
  },
  extraReducers: {
    [getUserInfo.pending.type]: (state, action) => {
      state.userInfo = {
        status: true,
        data: null,
        error: {},
      };
    },
    [getUserInfo.fulfilled.type]: (state, action) => {
      state.userInfo = {
        status: false,
        data: action.payload,
        error: {},
      };
    },
    [getUserInfo.rejected.type]: (state, action) => {
      state.userInfo = {
        status: true,
        data: null,
        error: action.payload,
      };
    },
    [getAuth.pending.type]: (state, action) => {
      state.auth = {
        status: true,
        data: null,
        error: {},
      };
    },
    [getAuth.fulfilled.type]: (state, action) => {
      state.auth = {
        status: false,
        data: action.payload,
        error: {},
      };
    },
    [getAuth.rejected.type]: (state, action) => {
      state.auth = {
        status: true,
        data: null,
        error: action.payload,
      };
    },
  },
});

export const { updateUserInfoLocally, clearUserState } = user.actions;

export default user.reducer;
