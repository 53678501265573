import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/api.service";

export const fetchListTopics = createAsyncThunk(
  "topics/fetchTopics",
  async ({ userID, type, topicType, page }) => {
    const visibilityType = type;
    const id = localStorage.getItem("ewUserID");
    console.log(
      "userID, type, topicType, page ->>>>>>>>>>>>>",
      userID,
      type,
      topicType,
      page
    );
    const pageNo = page;
    try {
      switch (visibilityType) {
        case "0":
          const data0 = await ApiService.getTopicListMini({
            topicType: topicType ? topicType : 0,
            searchType: 1,
            interaction: 0,
            page: pageNo,
          });
          data0.myTopics = data0?.myTopics?.sort((a, b) =>
            a.dateUpdated < b.dateUpdated ? 1 : -1
          );
          return data0;
        case "1":
          const data1 = await ApiService.getTopicListMini({
            topicType: topicType ? topicType : 0,
            searchType: 1,
            interaction: 1,
            page: pageNo,
          });
          data1.myTopics = data1?.myTopics?.filter(
            (topic) => topic.visibility === "1"
          );
          return data1;

        case "3":
          const data3 = await ApiService.getTopicListMini({
            topicType: topicType ? topicType : 0,
            searchType: 1,
            interaction: 3,
            page: pageNo,
          });
          data3.myTopics = data3?.myTopics?.filter(
            (topic) => topic.visibility === "3"
          );
          return data3;

        case "4":
          const data4 = await ApiService.getTopicListMini({
            topicType: topicType ? topicType : 0,
            searchType: 1,
            interaction: 4,
            page: pageNo,
          });
          data4.myTopics = data4?.myTopics?.filter(
            (topic) => topic.visibility === "4"
          );
          return data4;

        case "5":
          const data5 = await ApiService.getTopicListMini({
            topicType: topicType ? topicType : 0,
            searchType: 1,
            interaction: 5,
            page: pageNo,
          });
          data5.myTopics = data5?.myTopics?.filter(
            (topic) => topic.visibility === "5"
          );
          return data5;

        case "6":
          const data6 = await ApiService.getTopicListMini({
            topicType: topicType ? topicType : 0,
            searchType: 1,
            interaction: 6,
            page: pageNo,
          });
          data6.myTopics = data6?.myTopics?.filter(
            (topic) => topic.visibility === "6"
          );
          return data6;
        default:
          const data = await ApiService.getTopicListMini({
            topicType: topicType,
            searchType: 1,
            interaction: 0,
            page: pageNo,
          });
          data.myTopics = data?.myTopics?.sort((a, b) =>
            a.dateUpdated < b.dateUpdated ? 1 : -1
          );
          return data;
      }
      // switch (visibilityType) {
      //   case "0":
      //     // call api for Tab0
      //     return await ApiService.getTimeline({
      //       userID: userID,
      //       topicType: topicType,
      //       page: pageNo,
      //     });
      //     break;
      //   case "3":
      //     // call api for Tab1
      //     const data = await ApiService.getTopicListMini({
      //       interaction: 3,
      //       searchType: 1,
      //       topicType: topicType ? topicType : 0,
      //       page: pageNo,
      //     });
      //     data.myTopics = data?.myTopics?.filter(
      //       (topic) => topic.visibility === "3"
      //     );
      //     return data;
      //     break;
      //   case "2":
      //     // call api for Tab2
      //     const data2 = await ApiService.getTopics({
      //       searchType: 2,
      //       topicType: topicType,
      //       ownerUserID: userID,
      //       page: pageNo,
      //     });
      //     data2.myTopics = data2?.myTopics?.filter(
      //       (topic) => topic.visibility === "6"
      //     );
      //     return data2;
      //     break;
      //   case "3":
      //     // call api for Tab3
      //     const data3 = await ApiService.getTopics({
      //       searchType: 2,
      //       topicType: topicType,
      //       ownerUserID: userID,
      //       page: pageNo,
      //     });
      //     data3.myTopics = data3?.myTopics?.filter(
      //       (topic) => topic.visibility === "5"
      //     );
      //     return data3;
      //     break;
      //   case "4":
      //     // call api for Tab4
      //     return await ApiService.getTimeline({
      //       userID: userID,
      //       topicType: topicType,
      //       ownerUserID: userID,
      //       page: pageNo,
      //     });
      //     break;
      //   case "5":
      //     // call api for Tab5
      //     const data4 = await ApiService.getTopics({
      //       searchType: 2,
      //       topicType: topicType,
      //       ownerUserID: userID,
      //       page: pageNo,
      //     });
      //     data4.myTopics = data4?.myTopics?.filter(
      //       (topic) => topic.visibility === "4"
      //     );
      //     return data4;
      //     break;
      //   case "6":
      //     // call api for Tab5
      //     const data5 = await ApiService.getTopics({
      //       searchType: 2,
      //       topicType: topicType,
      //       ownerUserID: userID,
      //       page: pageNo,
      //     });
      //     data5.myTopics = data5?.myTopics?.filter(
      //       (topic) => topic.visibility === "1"
      //     );
      //     return data5;
      //     break;
      //   default:
      //     // return await ApiService.getTopics({
      //     //   ownerUserID: userID ? userID : id,
      //     //   searchType: 4,
      //     //   topicType: topicType,
      //     //   page: pageNo,
      //     // });
      //     // call api for Tab6
      //     const data6 = await ApiService.getTopicListMini({
      //       topicType: topicType,
      //       searchType: 1,
      //       interaction: 0,
      //       page: pageNo,
      //     });
      //     data6.myTopics = data6?.myTopics?.sort((a, b) =>
      //       a.dateUpdated < b.dateUpdated ? 1 : -1
      //     );
      //     return data6;
      //     // return await ApiService.getTopicListMini({
      //     //   topicType: topicType,
      //     //   searchType: 1,
      //     //   interaction: 0,
      //     //   page: pageNo,
      //     // });
      //     break;
      // }
    } catch (error) {
      console.log("ERROR", error);
    }
  }
);

export const fetchListTopicsPage = createAsyncThunk(
  "topics/fetchTopicsPage",
  async ({ userID, type, topicType, page }) => {
    const visibilityType = type;
    const pageNo = page;
    try {
      switch (visibilityType) {
        case "0":
          // call api for Tab0
          return await ApiService.getTimeline({
            userID: userID,
            searchType: 2,
            topicType: topicType,
            page: pageNo,
          });
          break;
        case "1":
          // call api for Tab1
          const data = await ApiService.getTopics({
            searchType: 2,
            topicType: topicType,
            ownerUserID: userID,
            page: pageNo,
          });
          data.myTopics = data?.myTopics?.filter(
            (topic) => topic.visibility === "3"
          );
          return data;
          break;
        case "2":
          // call api for Tab2
          const data2 = await ApiService.getTopics({
            searchType: 2,
            topicType: topicType,
            ownerUserID: userID,
            page: pageNo,
          });
          data2.myTopics = data2?.myTopics?.filter(
            (topic) => topic.visibility === "6"
          );
          return data2;
          break;
        case "3":
          // call api for Tab3
          const data3 = await ApiService.getTopics({
            searchType: 2,
            topicType: topicType,
            ownerUserID: userID,
            page: pageNo,
          });
          data3.myTopics = data3?.myTopics?.filter(
            (topic) => topic.visibility === "5"
          );
          return data3;
          break;
        case "4":
          // call api for Tab4
          return await ApiService.getTimeline({
            userID: userID,
            searchType: 2,
            topicType: topicType,
            page: pageNo,
          });
          break;
        case "5":
          // call api for Tab5
          const data4 = await ApiService.getTopics({
            searchType: 2,
            topicType: topicType,
            ownerUserID: userID,
            page: pageNo,
          });
          data4.myTopics = data4?.myTopics?.filter(
            (topic) => topic.visibility === "4"
          );
          return data4;
          break;
        case "6":
          // call api for Tab5
          const data5 = await ApiService.getTopics({
            searchType: 2,
            topicType: topicType,
            ownerUserID: userID,
            page: pageNo,
          });
          data5.myTopics = data5?.myTopics?.filter(
            (topic) => topic.visibility === "1"
          );
          return data5;
          break;
        default:
          return await ApiService.getTopics({
            ownerUserID: userID,
            searchType: 4,
            topicType: topicType,
            page: pageNo,
          });
          break;
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }
);

const initialState = {
  topics: {
    data: [],
    status: false,
    error: {},
  },
};

export const topics = createSlice({
  name: "topics",
  initialState,
  reducers: {
    updateLocalXYPosition: (state, action) => {
      const { id, newX, newY } = action.payload;

      const currentDataList = state.list.data;

      currentDataList.forEach((item, index) => {
        if (item.id === id) {
          currentDataList[index] = {
            ...currentDataList[index],
            y: newY,
            x: newX,
          };
        }
      });

      state.list.data = currentDataList;
    },
    addingNewLabel: (state, action) => {
      const { id, newLable } = action.payload;

      const currentDataList = state.list.data;

      currentDataList.forEach((item, index) => {
        if (item.id === id) {
          currentDataList[index] = {
            ...currentDataList[index],
            labels: [...currentDataList[index].labels, newLable],
          };
        }
      });

      state.list.data = currentDataList;
    },
    removeALabel: (state, action) => {
      const { noteId, labelId } = action.payload;

      const currentDataList = state.list.data;

      currentDataList.forEach((item, index) => {
        if (item.id === noteId) {
          let currentLabelsList = currentDataList[index].labels;

          const deletePosition = currentLabelsList.findIndex(
            (item) => item.id === labelId
          );

          currentLabelsList.splice(deletePosition, 1);

          currentDataList[index] = {
            ...currentDataList[index],
            labels: currentLabelsList,
          };
        }
      });

      state.list.data = currentDataList;
    },
    updateSingleLabelContent: (state, action) => {
      const { noteId, labelId, editValue } = action.payload;

      state.list.status = true;
      const currentDataList = state.list.data;

      currentDataList.forEach((item, index) => {
        if (item.id === noteId) {
          let currentLabelsList = currentDataList[index].labels;

          const changedPosition = currentLabelsList.findIndex(
            (item) => item.id === labelId
          );

          currentLabelsList[changedPosition] = {
            ...currentLabelsList[changedPosition],
            content: editValue,
          };

          currentDataList[index] = {
            ...currentDataList[index],
            labels: currentLabelsList,
          };
        }
      });

      state.list.status = false;
      state.list.data = currentDataList;
    },
    clearTopicsState: (state, action) => {
      state.topics = {};
    },
    filterTopicType: (state, action) => {
      const currentTopicState = JSON.parse(JSON.stringify(state.topics));
      const currentPayload = action.payload;
      const filtered = currentTopicState?.data?.myTopics?.filter(
        (topic) => topic.topicType === currentPayload
      );
      currentTopicState.data.myTopicsUnfiltered =
        currentTopicState?.data?.myTopics;
      currentTopicState.data.myTopics = filtered;
      state.topics = currentTopicState;
    },
  },
  extraReducers: {
    [fetchListTopics.pending.type]: (state, action) => {
      state.topics = {
        status: true,
        data: [],
        error: {},
      };
    },
    [fetchListTopics.fulfilled.type]: (state, action) => {
      const currentPayload = action.payload;
      const currentTopics = (currentPayload && currentPayload.myTopics) || [];
      state.topics = {
        status: false,
        data: currentPayload,
        error: {},
      };
    },
    [fetchListTopics.rejected.type]: (state, action) => {
      state.topics = {
        status: false,
        data: [],
        error: action.payload,
      };
    },
    [fetchListTopicsPage.fulfilled.type]: (state, action) => {
      const currentTopicState = JSON.parse(JSON.stringify(state.topics));
      const currentPayload = action.payload;
      const newTopics = currentPayload.myTopics;
      currentPayload.myTopics = [
        ...currentTopicState.data.myTopics,
        ...newTopics,
      ];
      currentPayload.myTopicsUnfiltered = [
        ...currentTopicState.data.myTopics,
        ...newTopics,
      ];
      state.topics = {
        status: false,
        data: currentPayload,
        error: {},
      };
    },
  },
});

export const {
  updateLocalXYPosition,
  addingNewLabel,
  removeALabel,
  updateSingleLabelContent,
  clearTopicsState,
  filterTopicType,
} = topics.actions;

export default topics.reducer;
