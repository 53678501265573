import axios from "axios";
import { handleLogOut } from "./common";

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  mode: "cors",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  credentials: "same-origin",
  withCredentials: true,
  //timeout: 60000
});

const sleepRequest = (milliseconds, originalRequest) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(service(originalRequest)), milliseconds);
  });
};

// Config
const ENTRY_ROUTE = "/login";
const TOKEN_PAYLOAD_KEY = "Authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    // const jwtToken = localStorage.getItem(AUTH_TOKEN);
    // if (jwtToken) {
    //   config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    // }
    //
    // if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    //   history.push(ENTRY_ROUTE);
    //   window.location.reload();
    // }

    return config;
  },
  (error) => {
    // Do something with request error here
    // notification.error({
    //   message: "Error"
    // });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    if (
      response.data.isError &&
      response.data.errorCat === 1 &&
      response.data.errorMessage ==
        "Your Oddlyou session has expired. Please log in again to continue."
    ) {
      handleLogOut();
      window.location.replace(ENTRY_ROUTE);
    }
    return response.data;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    let notificationParam = {
      message: "",
    };

    // Remove token and redirect
    if (
      error.response.status === 400 ||
      error.response.status === 401 ||
      error.response.status === 403
    ) {
      //notificationParam.message = "Authentication Fail";
      //notificationParam.description = "Please login again";
      //localStorage.removeItem(AUTH_TOKEN);
      //history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (error.response.status === 404) {
      //notificationParam.message = "Not Found";
    }

    if (error.response.status === 500) {
      //notificationParam.message = "Internal Server Error";
    }
    if (error.response.status === 502) {
      //return sleepRequest(5000, originalRequest);
    }
    if (error.response.status === 508) {
      //notificationParam.message = "Time Out";
    }

    //notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
