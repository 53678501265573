import { Carousel } from "antd";
import React, { useReducer, useContext, useState, useRef } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { imagesData } from "../../../constants/images";
const { Feed, PrivacyShare, ConnectionAdd, NoTopicNotes } = imagesData;
const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
};

export default function Walkthrough() {
  const carouselRef = useRef();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  return (
    <div className="container-fluid">
      <Modal
        title="Modal 1000px width"
        centered
        show={true}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        fullscreen
        className="initialModal"
      >
        <>
          <div className="row-flex justify-content-between h-25 align-items-center">
            <div></div>

            <div className={hide ? "d-none" : ""}>
              <Button
                className="skipBtn"
                onClick={() => carouselRef.current.next()}
              >
                Next
              </Button>
              <Button
                className="skipBtn"
                onClick={() => history.push("/newsignup")}
              >
                Skip
              </Button>
            </div>
          </div>
          <Carousel
            effect="fade"
            className="initialCarousel"
            ref={carouselRef}
            dots={(dots) => <div className="dots">{dots}</div>}
            beforeChange={(a, b, c) => {
              if (b === 3) {
                setHide(true);
              } else {
                setHide(false);
              }
            }}
          >
            <div className="firstSlide">
              <div>
                <div>
                  <img src={Feed} />
                </div>
                <h2 className="my-3">Topics</h2>
                <label>
                  Use topics to add meaning and context your life ,totaly
                  private or shared with only you'd like
                </label>
                <label className="my-3">
                  Topics are about what you want them to be.
                  <br />
                  Create topics about anyting you'd like.
                </label>
              </div>
            </div>
            <div className="secondSlide">
              <div>
                <h2 className="my-3">Privacy</h2>
                <label>
                  Topics are always PRIVATE unless you decide to share them with
                  other
                </label>
                <label className="my-3">
                  You decide exactly how and who to share topic with
                </label>
              </div>
              <div>
                <img src={PrivacyShare} width={"50%"} />
              </div>
            </div>
            <div className="thirdSlide">
              <div>
                <h2 className="my-3">Connection</h2>
                <label>
                  Connection with other so you can share your topics with them,
                  <br />
                  and they can share their topics with you
                </label>
              </div>
              <div className="my-3">
                <img src={ConnectionAdd} width={"40%"} />
              </div>
            </div>
            <div className="fourthSlide">
              <div>
                <h2 className="my-3">
                  You're ready for the <br />
                  topics
                </h2>
                <h5>You're ready for the topics!</h5>
                <label>
                  Create and share the content you want,in the way you want to,
                  without the distraction of endless feed
                  <br />
                  and they can share their topics with you
                </label>
              </div>
              <div className="my-5 row-flex justify-content-center">
                <img src={NoTopicNotes} width={"70%"} />
              </div>
              <div className="my-5 row-flex justify-content-center">
                <Button
                  className="skipBtn"
                  onClick={() => history.push("/newsignup")}
                >
                  Let's Start
                </Button>
              </div>
            </div>
          </Carousel>
        </>
      </Modal>
    </div>
  );
}
