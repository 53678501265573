export const path = {
  DEFAULT: "/",
  STYLEGUIDE: "/styleguide",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGETPASSWORD: "/forget-password",
  TOPICDETAILS: "/topic-details",
  DASHBOARD: "/dashboard",
  RECENTSHARE: "/recent-share",
  TOPIC: "/recent-share/topic",
  UPLOADS: "/uploads",
  CONNECTIONS: "/connections",
  GROUPS: "/groups",
  PROFILE: "/profile",
  NOTIFICATION: "/notification",
  GROUPDETAILS: "/groups/details",
  ADDMEMBER: "/groups/add-member",
  SEARCH: "/search",
  CREATE: "/create",
  UploadFolderDetails: "/uploads/:id",
  NEWSIGNUP: "/newsignup",
  Walkthrough: "/walkthrough",
  EDIT: "/edit",
};
