import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  isLoading: false,
};

// export const addNotificationThunk = createAsyncThunk(
//   "notifications/addNotificationThunk",
//   async ({ type }) => {
//     console.log("notifications/addEntryLocalStorage ->>>>>>>>>>>>>", type);
//     try {
//       // Parse any JSON previously stored in allEntries
//       let existingEntries = localStorage.getItem("ewNotifications");
//       existingEntries = existingEntries
//         ? JSON.parse(localStorage.getItem("ewNotifications"))
//         : [];
//       if (existingEntries === null) existingEntries = [];
//       var entry = event;
//       localStorage.setItem("ewEvent", JSON.stringify(entry));
//       // Save allEntries back to local storage
//       existingEntries.push(entry);
//       localStorage.setItem("ewNotifications", JSON.stringify(existingEntries));
//       return;
//     } catch (error) {
//       console.log("Error notification", error);
//     }
//   }
// );

let notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    getNotifications: function (state) {
      state.isLoading = true;
    },
    getNotificationsSuccess: function (state, action) {
      // action.payload = notifications {array of events objects}
      state.notifications = action.payload;
      state.isLoading = false;
    },
    getNotificationsFailure: function (state) {
      state.isLoading = false;
    },
    setNotifications: function (state, action) {
      // action.payload = notifications {array of events objects}
      state.notifications = action.payload;
    },
    addNotification: function (state, action) {
      console.log("action notification ", action);
      // action.payload = notification {object}
      state.notifications.push(action.payload);
    },
    deleteNotification: function (state, action) {
      // action.payload = id {number}
      state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    deleteAllNotifications: function (state) {
      state.notifications = [];
    },
  },
});
export const {
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailure,
  setNotifications,
  addNotification,
  deleteNotification,
  deleteAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
