import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/api.service";

export const fetchListConnections = createAsyncThunk(
  "connections/fetchConnections",
  async ({ userID, location, profession }) => {
    try {
      const res = await ApiService.getOtherRecommendations({
        userID: userID,
        location: location,
        profession: profession,
      });
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  }
);

const initialState = {
  connections: {
    data: [],
    status: false,
    error: {},
  },
};

export const connections = createSlice({
  name: "connections",
  initialState,
  reducers: {
    clearConnectionsState: (state, action) => {
      state.connections = {};
    },
  },
  extraReducers: {
    [fetchListConnections.pending.type]: (state, action) => {
      state.connections = {
        status: true,
        data: [],
        error: {},
      };
    },
    [fetchListConnections.fulfilled.type]: (state, action) => {
      state.connections = {
        status: false,
        data: action.payload,
        error: {},
      };
    },
    [fetchListConnections.rejected.type]: (state, action) => {
      state.connections = {
        status: false,
        data: [],
        error: action.payload,
      };
    },
  },
});

export const { clearConnectionsState } = connections.actions;

export default connections.reducer;
