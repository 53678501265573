import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userInfo";
import topicsReducer from "./topics/topic";
import connectionsReducer from "./connections/connection";
import notificationReducer from "./notifications/notification";

export const store = configureStore({
  reducer: {
    user: userReducer,
    topics: topicsReducer,
    connections: connectionsReducer,
    notifications: notificationReducer,
  },
});
