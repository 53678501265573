import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import UnauthenticatedRoute from "./UnAuthenticatedRoute";
import PrivateRoute from "./PrivateRoutes";
import { useAppContext } from "../libs/context-libs";
import { createBrowserHistory } from "history";
import Spinner from "react-bootstrap/Spinner";

import { path } from "./routePath";
import Walkthrough from "../views/onboarding/walkthrough";

const SignUp = lazy(() => import("../views/onboarding/Signup"));
const Login = lazy(() => import("../views/onboarding/Login"));
const ForgetPassword = lazy(() =>
  import("../views/onboarding/ForgetPassword/index")
);
//const Styleguide = lazy(() => import("../views/styleguide"));
const TopicDetails = lazy(() => import("../views/topicdetails"));
const DashBoard = lazy(() => import("../views/dashboard"));
const RecentShare = lazy(() => import("../views/recentshare"));
const Uploads = lazy(() => import("../views/uploads"));
const Connections = lazy(() => import("../views/connections"));
const Groups = lazy(() => import("../views/groups"));
const GroupDetails = lazy(() => import("../views/groups/groupDetails"));
const AddMember = lazy(() => import("../views/groups/addMember"));
const Profile = lazy(() => import("../views/profile"));
const RecentShareTopic = lazy(() => import("../views/recentshare/topic"));
const Notification = lazy(() => import("../views/notification"));
const Search = lazy(() => import("../views/search"));
const UploadFolderDetails = lazy(() =>
  import("../views/uploads/folderDetails")
);
const NewSignup = lazy(() => import("../views/profile/newSignUp"));
const Create = lazy(() => import("../views/create"));
const EditTopic = lazy(() => import("../views/edit"));

export default function Routes() {
  const { isAuthenticated } = useAppContext();
  const hist = createBrowserHistory();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <UnauthenticatedRoute exact path={path.LOGIN}>
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path={path.FORGETPASSWORD}>
          <ForgetPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path={path.SIGNUP}>
          <SignUp />
        </UnauthenticatedRoute>
        <Switch>
          <Route
            path={path.Walkthrough}
            render={(props) => (
              <Walkthrough {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <Route
            path={path.NEWSIGNUP}
            render={(props) => (
              <NewSignup {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          <Route
            path="/topic/:topicID"
            render={(props) => (
              <TopicDetails
                {...props}
                isAuthenticated={isAuthenticated}
                location={props.location}
              />
            )}
          />
          <Route
            path="/recent-share/:topicID/:userID"
            render={(props) => (
              <RecentShare
                {...props}
                isRecentShare={true}
                isAuthenticated={isAuthenticated}
                location={props.location}
              />
            )}
          />
          <Route
            path="/profile/:userID/:topicID"
            render={(props) => (
              <Profile {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <Route
            path="/uploads/:id"
            render={(props) => (
              <UploadFolderDetails
                {...props}
                isAuthenticated={isAuthenticated}
              />
            )}
          />
          <Route
            path="/recent-share"
            render={(props) => (
              <RecentShare
                {...props}
                isRecentShare={true}
                isAuthenticated={isAuthenticated}
                location={props.location}
              />
            )}
          />

          {/* <PrivateRoute
            exact
            isAuthenticated={isAuthenticated}
            component={
              <TopicDetails
                {...props}
                isRecentShare={true}
                isAuthenticated={isAuthenticated}
                location={props.location}
              />
            }
            path={path.RECENTSHARE}
          /> */}
          <PrivateRoute
            exact
            isAuthenticated={isAuthenticated}
            component={TopicDetails}
            path={path.DASHBOARD}
          />
          <Route
            path="/profile/:id"
            render={(props) => (
              <Profile {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <Route
            path="/groups/:id/details"
            render={(props) => (
              <GroupDetails {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <Route
            path="/search"
            render={(props) => (
              <Search {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <Route
            path={"/groups/add-member/"}
            render={(props) => (
              <AddMember {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            component={Profile}
            path={path.PROFILE}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            component={Uploads}
            path={path.UPLOADS}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            component={Connections}
            path={path.CONNECTIONS}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            component={Groups}
            path={path.GROUPS}
          />
          {/* <PrivateRoute
            isAuthenticated={isAuthenticated}
            component={AddMember}
            path={path.ADDMEMBER}
          /> */}

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            component={Notification}
            path={path.NOTIFICATION}
          />
          <Route
            path={path.CREATE}
            render={(props) => (
              <Create {...props} isAuthenticated={isAuthenticated} />
            )}
          />
          <Route
            path={path.EDIT}
            render={(props) => (
              <EditTopic {...props} isAuthenticated={isAuthenticated} />
            )}
          />
        </Switch>
        <Route exact path="" component={() => "PAGE NOT FOUND 404"} />
      </Switch>
    </Suspense>
  );
}
